// Copyright 2021
// Polus
import React from 'react';
import { graphql } from 'gatsby';
import { format, parseISO } from 'date-fns';

import Layout from '../../components/Layout';
import Seo from '../../components/SEO';
import Callout from '../../components/Callout';

export default function Template({ data }) {
  const { markdownRemark } = data;
  const { html, frontmatter } = markdownRemark;

  return (
    <Layout>
      <Seo title="Blog" />
      <div className="mx-auto mb-16 max-w-2xl space-y-8 px-2 md:px-4">
        <p className="text-sm font-bold text-gray-500">
          {frontmatter.author} &#8226; {format(parseISO(frontmatter.date), 'LLLL do, yyyy')}
        </p>
        <div
          className="prose"
          dangerouslySetInnerHTML={{ __html: html }}
          style={{ maxWidth: '100%' }}
        />
      </div>
      <Callout label="Start studying on Studius today, for free." />
    </Layout>
  );
}

export const pageQuery = graphql`
  query ($id: String!) {
    markdownRemark(id: { eq: $id }) {
      html
      frontmatter {
        date
        title
        author
      }
    }
  }
`;
